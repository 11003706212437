import { createAction } from "typesafe-actions";
import { RequestState } from "../constants/request-state";
import { Portfolio } from "../types/portfolio/Portfolio";
import { Color } from "../types/bwic/Color";

const request = createAction('entities/portfolio-list/Request');
const requestResult = createAction('entities/portfolio-list/RequestResult',
    resolve => (requestResult: RequestState, result: Portfolio[] = []) =>
        resolve({ requestResult, result })
);
const  resetEntities = createAction('entities/portfolio-list/Reset');
const colorDistributionSend = createAction('entities/portfolios-list/PublicColors',
    resolve => (data: { colors: { positionId: number, color: Color[] }, bwicReferenceName: string, historyAction: {} }) => resolve({ data })
);
const bwicStatusChanged = createAction('entities/portfolio-list/BwicStatusChange',
    resolve => (data: { bwicStatus: string, bwicReferenceName: string, historyAction: {} }) => resolve({ data })
);
const updateSendAlertState = createAction(
    'entities/portfolio-list/UpdateSendAlertState',
    resolve =>
        (
            sendAlertState: {
                portfolioId: number;
                bwicAlert?: boolean;
                dealersInventoryAlert?: boolean;
                issuanceMonitorAlert?: boolean;
                rollerDeadlineAlert?: boolean;
            }[],
        ) =>
            resolve({ sendAlertState }),
);

const deletePortfolio = createAction('entities/portfolio-list/DeletePortfolio',
    resolve => (portfolioId: number) => resolve({ portfolioId }));

const reset = createAction('entities/portfolio-list/Reset');

export const portfolioListActions = {
    request,
    requestResult,
    resetEntities,
    colorDistributionSend,
    bwicStatusChanged,
    updateSendAlertState,
    deletePortfolio,
    reset
}
