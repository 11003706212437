import { createAction } from "typesafe-actions";
import { RequestState } from "../constants/request-state";
import { AlertOption, StatisticsAlertOption } from "../types/amr-pipeline/enums/AlertOption";
import { PipelineType } from '../types/amr-pipeline/enums/PipelineType';
import { UserConfigBase, ImUserConfig, UserConfigFilterType, CompanyAlertsValue } from "../types/user-config/UserConfig";
import { UserConfigFilter, UserFilterAlertStatus } from "../types/user-config/UserConfigFilter";
import { UserConfigType } from "../types/user-config/UserConfigType";
import { UserConfigTransaction } from "../types/user-config/UserConfigTransaction";

const getUserConfig = createAction(
    'userconfig/get/REQUEST',
    resolve => (callback?: () => void) => resolve({ callback })
);

const getUserConfigResult = createAction('userconfig/get/REQUEST_RESULT',
    resolve => (requestResult: RequestState, userConfig?: ImUserConfig[]) =>
        resolve({ requestResult, userConfig })
);

const getUserFilterConfig = createAction(
    'userconfig/get/filter/REQUEST',
    resolve => (configType?: UserConfigType.imFilter | UserConfigType.apFilter) => resolve({ configType })
);

const getUserFilterConfigResult = createAction('userconfig/get/filter/REQUEST_RESULT',
    resolve => (requestResult: RequestState, userConfig?: ImUserConfig[]) =>
        resolve({ requestResult, userConfig })
);

const updateUserConfig = createAction(
    'userconfig/update/REQUEST',
    resolve => (userConfig: UserConfigBase[]) => {
        const imUserConfig: ImUserConfig[] = userConfig.map(config => ({
            ...config,
            value: JSON.stringify(config.value),
        }));

        return resolve({ userConfig: imUserConfig });
    },
);

const updateUserConfigResult = createAction(
    'userconfig/update/REQUEST_RESULT',
    resolve => (requestResult: RequestState, userConfig?: ImUserConfig[]) =>
        resolve({ requestResult, userConfig })
);

const reset = createAction('userconfig/RESET');

const getDefaultUserColumnsConfig = createAction('userconfig/columns/default/REQUEST');

const getDefaultUserColumnsConfigResult = createAction(
    'userconfig/columns/default/REQUEST_RESULT',
    resolve => (requestResult: RequestState, columnConfig?: ImUserConfig[]) =>
        resolve({ requestResult, columnConfig })
);

const selectUserFilter = createAction(
    'userconfig/filter/select/REQUEST',
    resolve => (userFilter: UserConfigFilter) =>
        resolve(userFilter)
)
const createUserFilter = createAction(
    'userconfig/filter/create/REQUEST',
    resolve => (pipelineType: PipelineType, userFilter: { name: string; default: boolean; alertOption: AlertOption }) =>
        resolve({ pipelineType, userFilter })
);

const createUserFilterResult = createAction(
    'userconfig/filter/create/REQUEST_RESULT',
    resolve => (requestResult: RequestState, userConfigFilter?: UserConfigFilter) =>
        resolve({ requestResult, userConfigFilter })
);

const updateUserFilter = createAction(
    'userconfig/filter/update/REQUEST',
    resolve => (pipelineType: PipelineType, userFilter: { referenceName: string; name: string; default: boolean; alertOption: AlertOption }) =>
        resolve({ pipelineType, userFilter })
);

const updateUserFilterResult = createAction(
    'userconfig/filter/update/REQUEST_RESULT',
    resolve => (requestResult: RequestState, userConfigFilter?: UserConfigFilter) =>
        resolve({ requestResult, userConfigFilter })
);

const setUserFilterParams = createAction(
    'userconfig/filter/set_params/REQUEST',
    resolve => (params: Partial<UserConfigFilter>, filterType: UserConfigFilterType) =>
        resolve({ filterType, ...params }),
);

const setUserFilterParamsResult = createAction(
    'userconfig/filter/set_params/REQUEST_RESULT',
    resolve => (requestResult: RequestState, filterType: UserConfigFilterType, userConfigFilter?: UserConfigFilter) =>
        resolve({ requestResult, filterType, userConfigFilter })
);

const deleteUserFilter = createAction(
    'userconfig/filter/delete/REQUEST',
    resolve => (referenceName: string, filterType: UserConfigFilterType) => resolve({ referenceName, filterType })
);

const deleteUserFilterResult = createAction(
    'userconfig/filter/delete/REQUEST_RESULT',
    resolve => (requestResult: RequestState, filterType: UserConfigFilterType, referenceName?: string) =>
        resolve({ requestResult, referenceName, filterType })
);

const updateUserFilterEmailAlerts = createAction(
    'userconfig/filter/email-alerts/update/REQUEST',
    resolve => (
        singleUpdateAlertOption: AlertOption,
        newTransactionAlertOption: AlertOption,
        statisticAlertOption: StatisticsAlertOption,
        filterEmailAlerts: UserFilterAlertStatus[]
    ) => resolve({ singleUpdateAlertOption, newTransactionAlertOption, statisticAlertOption, filterEmailAlerts })
);

const saveTransactionAlerts = createAction(
    'userconfig/transaction/alerts/save/REQUEST',
    resolve => (configs: {type: UserConfigType, value: UserConfigTransaction}[]) => resolve({ configs })
);

const saveCompanyAlerts = createAction(
    'userconfig/company/alerts/save/REQUEST',
    resolve => (configs: {type: UserConfigType, value: CompanyAlertsValue}[]) => resolve({ configs }));



export const imUserConfigActions = {
    getUserConfig,
    getUserConfigResult,
    getUserFilterConfig,
    getUserFilterConfigResult,
    updateUserConfig,
    updateUserConfigResult,
    saveTransactionAlerts,
    saveCompanyAlerts,
    getDefaultUserColumnsConfig,
    getDefaultUserColumnsConfigResult,
    reset,
    selectUserFilter,
    createUserFilter,
    updateUserFilter,
    createUserFilterResult,
    updateUserFilterResult,
    setUserFilterParams,
    setUserFilterParamsResult,
    deleteUserFilter,
    deleteUserFilterResult,
    updateUserFilterEmailAlerts,
};
